import React from "react";

import logo from "../../Images/Nav/icon.png";

import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

import "../../CSS/footer.css";

import { FaLocationDot } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import { FaPhoneAlt } from "react-icons/fa";

function Footer() {
  const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 767 });
    return isMobile ? children : null;
  };
  const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 992 });
    return isDesktop ? children : null;
  };
  return (
    <>
      <Mobile>
      <div className="footerUpperMain">
        <div className="footerMobMain">
          <div className="footerMobImg">
            <img height={100} src={logo} alt="logo" />
            <p style={{ fontStyle: "italic", width: "50%" }}>
              "We breathe life into your industrial processes with touch of
              excellence."
            </p>
          </div>
          <div className="footerMobContact">
            <h1 style={{ textAlign: "center" }}>Get in Touch !</h1>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                paddingLeft: "3%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  marginTop: "1rem",
                }}
              >
                <FaLocationDot style={{ color: "#A94438", fontSize: "2rem" }} />
                <span style={{ fontSize: "1rem", marginLeft: "1rem" }}>
                  Gate No. 1560; Plot no A-19,20 Shelarwasti Dehu Alandi Road,
                  <br />
                  Chikhali, Pune Maharastra 411062
                </span>
              </div>
              <div
                style={{
                  display: "flex",
                  marginTop: "3rem",
                }}
              >
                <MdEmail style={{ color: "#A94438", fontSize: "2rem" }} />
                <a 
                  style={{ all: "unset", cursor: "pointer" }}
                  href="mailto:punegasindustries@gmail.com"
                >
                  {" "}
                  <span className="anchor" style={{ fontSize: "1.1rem", marginLeft: "1rem" }}>
                    punegasindustries@gmail.com
                  </span>
                </a>
              </div>
              <div
                style={{
                  display: "flex",
                  marginTop: "3rem",
                }}
              >
                <FaPhoneAlt style={{ color: "#A94438", fontSize: "2rem" }} />
                <a 
                  style={{ all: "unset", cursor: "pointer" }}
                  href="tel:7410048724"
                >
                  {" "}
                  <span className="anchor" style={{ fontSize: "1.1rem", marginLeft: "1rem" }}>
                    +91 9326057779
                  </span>
                </a>
              </div>
            </div>
          </div>
          <div style={{display:'flex',justifyContent:'center',alignItems:'center',paddingBottom:'1rem',marginTop:'2rem'}}>
            <span style={{fontFamily:'Betm Font',fontSize:'1rem'}}>Designed and Developed By <br/> <img alt="codexslinger_Logo"  width={50} src={require('../../Images/CSLOGO/logopng.png')}/><span style={{fontFamily:'Betm Font',fontSize:'1.5rem'}}><span style={{color:'#214165'}}> code</span><span style={{color:'#0090D1'}}>X</span><span style={{color:'#214165'}}>slinger</span></span></span>
          </div>
        </div>
        </div>
      </Mobile>
      <Desktop>
        <div className="footerUpperMain">
        <div className="footerMain">
          <div className="footerImg">
            <img height={200} src={logo} alt="logo" />
            <p style={{ fontStyle: "italic" }}>
              "We breathe life into your industrial processes with touch of
              excellence."
            </p>
          </div>
          <div className="footerLinkMain">
            <h2 style={{ width: "10rem" }}>Top Links :</h2>
            <div className="footerLinks">
              <Link to="/" style={{ all: "unset" }}>
                {" "}
                <span>Home</span>{" "}
              </Link>
              <Link to="/products" style={{ all: "unset" }}>
                {" "}
                <span>Products</span>
              </Link>
              <Link to="/ourplant" style={{ all: "unset" }}>
                {" "}
                <span>Our Plant</span>
              </Link>
              <Link to="/contact" style={{ all: "unset" }}>
                <span>Contact Us</span>
              </Link>
            </div>
          </div>
          <div className="footerContact">
            <h1>Get in Touch !</h1>
            <div
              style={{
                display: "flex",
                marginTop: "3rem",
              }}
            >
              <FaLocationDot style={{ color: "#A94438", fontSize: "2rem" }} />
              <span style={{ fontSize: "1.2rem", marginLeft: "1rem" }}>
                Gate No. 1560; Plot no A-19,20 Shelarwasti Dehu Alandi Road,
                <br />
                Chikhali, Pune Maharashtra 411062
              </span>
            </div>
            <div
              style={{
                display: "flex",
                marginTop: "3rem",
              }}
            >
              <MdEmail style={{ color: "#A94438", fontSize: "2rem" }} />
              <a
              
                style={{ all: "unset", cursor: "pointer" }}
                href="mailto:punegasindustries@gmail.com"
              >
                {" "}
                <span  className="anchor" style={{ fontSize: "1.2rem", marginLeft: "1rem" }}>
                  punegasindustries@gmail.com
                </span>
              </a>
            </div>
            <div
              style={{
                display: "flex",
                marginTop: "3rem",
              }}
            >
              <FaPhoneAlt style={{ color: "#A94438", fontSize: "2rem" }} />
              <a
              
                style={{ all: "unset", cursor: "pointer" }}
                href="tel:7410048724"
              >
                {" "}
                <span className="anchor" style={{ fontSize: "1.2rem", marginLeft: "1rem" }}>
                  +91 9326057779
                </span>
              </a>
            </div>
          </div>
        </div>
          {/* <hr/> */}
          <div style={{display:'flex',justifyContent:'center',alignItems:'center',paddingBottom:'1rem'}}>
            <span style={{fontFamily:'Betm Font',fontSize:'1.5rem'}}>Designed and Developed By <img  width={50} alt="codexslinger_Logo" src={require('../../Images/CSLOGO/logopng.png')}/><span style={{fontFamily:'Betm Font',fontSize:'1.5rem'}}><span style={{color:'#214165'}}> code</span><span style={{color:'#0090D1'}}>X</span><span style={{color:'#214165'}}>slinger</span></span></span>
          </div>
        </div>
      </Desktop>
    </>
  );
}

export default Footer;
