import React from "react";
import { InfinitySpin } from "react-loader-spinner";

function Loading() {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <InfinitySpin
        visible={true}
        width="200"
        color="#a94438"
        ariaLabel="infinity-spin-loading"
      />
    </div>
  );
}

export default Loading;
