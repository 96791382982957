import React from "react";
import "./App.css";
import Loading from "../src/Components/Util/Loading";
import Brochure from "./Components/Brochure/Brochure";
import { Suspense } from "react";
import Footer from "./Components/Footer/Footer";
import ScrollToTop from "./Components/Util/ScrollToTop";
import { BrowserRouter, Routes, Route } from "react-router-dom";
const Home = React.lazy(() => import("./Components/Home/Home"));
const OurProducts = React.lazy(() =>
  import("./Components/OurProducts/OurProducts")
);
const OurPlant = React.lazy(() => import("./Components/OurPlant/OurPlant"));
const Contact = React.lazy(() => import("./Components/ContactUs/Contact"));

function App() {
  return (
    <>
      <BrowserRouter>
        <ScrollToTop />
        <Brochure/>
        <Suspense fallback={<Loading />}>
          <body>
            <Routes>
              <Route index element={<Home />} />
              <Route path="products" element={<OurProducts />} />
              <Route path="ourplant" element={<OurPlant />} />
              <Route path="contact" element={<Contact />} />
            </Routes>
          </body>
        </Suspense>
        <footer>
          <Footer />
        </footer>
      </BrowserRouter>
    </>
  );
}

export default App;
