import React from 'react'
import "../../CSS/brocher.css"
import { LuDownload } from "react-icons/lu";

import BroPdf from "./brochure.pdf";

function Brochure() {
  return (
    <>
        <div className='brocherMain'>
        <a href={BroPdf} rel='noreferrer' target='_blank' > <button class="button-57" role="button"><span class="text">Download Brochure </span><span> <LuDownload style={{fontSize:'1.5rem'}}/></span></button></a>
        </div>
    </>
  )
}

export default Brochure
